import React from 'react';
import Layout from "../components/layout";

// data
const team = [
    {
        profile: 'guy5.png',
        name: 'Guy Hadas',
        designation: 'Co-Founder & CEO',
        intro: 'Digital professional with over 10 years of experience. Guy is an experienced marketing manager, company director and a big believer in processes and operations.'
    },
    {
        profile: 'hadar.png',
        name: 'Hadar Swersky',
        designation: 'Co-Founder & Chairman',
        intro: 'Hadar is a serial entrepreneur, a former hedge fund manager who specializes in online finance, financial services and financing.'
    },
    // {
    //     profile: 'confi.png',
    //     name: 'To Be Disclosed',
    //     designation: 'CTO',
    //     intro: 'Master of code and development having served both governments and private companies, he has developed robust platforms most lately in the publishing space.'
    // },
    {
        profile: 'ofer.png',
        name: 'Ofer Akerman',
        designation: 'Head of Search',
        intro: 'Ofer specializes in achieving and maintaining Google top ranking in the most competitive markets. A true master of SEO, he has advised advertising networks, SEO agencies as well as multinationals with clients including Postbank, Omio and Monevo to name a few.'
    },
    {
        profile: 'inbal3.png',
        name: 'Inbal Lavi',
        designation: 'Advisor',
        intro: 'An experienced executive and director with expertise in online marketing, business strategy, entrepreneurship and operations. Former CEO of Webpals group (LON:XLM), a global leader in online performance marketing.'
    },
    {
        profile: 'michael.png',
        name: 'Michael Shmilov',
        designation: 'Advisor',
        intro: 'An experienced product manager and former COO of Rakuten Viber. Michael is passionate about product and monetization and assists this team with his keen eye for design and monetization strategies.'
    },
]

// markup
const AboutUs = () => {
    return (
        <Layout>
            <div className="wrapper">
                <div className='md:w-4/5 m-auto mt-8'>
                    <h1 className='text-4xl font-bold text-gray-51'>About Us</h1>
                    <div className='text-lg mt-8'>
                        <p>
                            We are AgeGroup, building the premier global brand for seniors worldwide through the creation of a dedicated digital universe – the AgeVerse. Our vision is to drive adoption through inclusion, and provide a safe and secure space for seniors to consume and interact online.
                        </p>
                        <p>
                            The AgeVerse is designed to be transparent, inclusive, and apolitical. It is as an alternative to traditional “seniors-focused” organizations who are slow to adapt to the shifting demographic and digital landscape.
                        </p>
                        <p>
                            Across multiple dedicated assets, platforms, online and offline activities, the AgeVerse helps the world’s over 50s to add life to their years by helping them find the information they need to help them make important decisions, connect them with new opportunities and activities in their local area or further afield and act as a platform where socially isolated seniors can make new friends and bond over common interests.
                        </p>
                        <p>
                            Here’s to bringing life to years!
                        </p>
                    </div>
                    <div className='mt-10'>
                        <div className="flex items-center">
                            <h1 className="relative text-4xl font-bold text-gray-51 news-title shrink-0">
                                Team Members
                            </h1>
                            <span className="head-line"></span>
                        </div>
                        <div className='flex md:flex-wrap md:flex-row flex-col text-center mb-[100px]'>
                            {team.map(member => {
                                return (
                                    <div className='w-9/12 w-full md:w-[31%] md:mr-[2.3%] mt-6 relative'>
                                        <div className='w-full'>
                                            <div className='text-center'>
                                                <img src={`/images/${member.profile}`} alt={"profle"} className="w-1/2 inline"/>
                                            </div>
                                            <div className='w-full bg-blue-50 rounded-[10px] text-white py-6 mt-4'>
                                                <h1 className='text-2xl font-bold'>{member.name}</h1>
                                                <p className='text-xl'>{member.designation}</p>
                                            </div>
                                            <div className='text-lg w-11/12 p-4 mx-auto'>
                                                <p>{member.intro}</p>
                                            </div>
                                        </div>
                                        <div className='absolute w-11/12 bottom-[0] h-[80%] md:h-[90%] lg:h-[80%] ml-[4%] bg-gray-52 z-[-1] rounded-[10px]'>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default AboutUs
